* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Source Sans 3", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.04px;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  font-family: "Rajdhani", sans-serif;
  margin: 0;
  padding: 0;
  line-height: 1.2;
}

ul {
  list-style: none;
  padding-left: 0;
  margin: 0;
}

a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  transition: color 250ms linear;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

address {
  font-style: normal;
}

html {
  scroll-behavior: smooth;
}

button {
  cursor: pointer;
}
